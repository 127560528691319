import React from "react"
import PropTypes from "prop-types"

const Stats = ({ Icon, description}) => {
  return (
    <div className="w-5/12 md:w-1/3 mr-5 flex flex-col items-center text-center">
      <Icon/>
      <p className="font-sans text-xs mt-6 opacity-75">{description}</p>
    </div>
  )
}

Stats.propTypes = {
  Icon: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired
}
  
export default Stats
